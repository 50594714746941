import React, { useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import '../header/header.css';
import logo from '../images/PROLA LMS Logo.svg';
import newLogo from '../images/PROLA-LMS LOGO.png'


const Header = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);


  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };


  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // const [navItem, setnavItem] = useState('home')

  // function activeBtn(){
  //   setnavItem(navItem)
  // }


  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={newLogo} alt="Logo" />
      </div>
      <label id='openBtn'for='checkBox'>&#9776;</label>
      <input type='checkbox' id='checkBox'/>
      <ul className="navbar-menu" id="menu1">
        <label id='closeBtn' for='checkBox'>&times;</label>
        <li id='home' >
          <NavLink to="/">Home</NavLink>
        </li>
        <li id='about' >
          <NavLink to="/about">About Us</NavLink>
        </li>
        <li id='testimonial' >
          <NavLink to="/testimonial">Testimonial</NavLink></li>
        <li id='contact' >
          <NavLink to="/contact">Contact Us</NavLink></li>
      </ul>
      {/* <div className="navbar-toggle" id="navbarToggle" onClick={togglePopup}>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <div className={`popup ${isPopupOpen ? 'open' : ''}`} id="popup">
        <div id="closeBtn" onClick={togglePopup}>
          <i className="fa-solid fa-xmark"></i>
        </div>
        <ul className="navbar-menu2" id="menu2" style={{ listStyle: 'none' }}>
        <li>
            <Link>&times;</Link>
          </li>
          <li>
            <Link to="/" >Home</Link>
          </li> */}
        
         
          {/* <li>        
      <div className="dropdown">
  <button onClick={toggleDropdown} className="dropdown-button">
     Solutions &#9662;
  </button>
  {isOpen && (
    
    <div className="dropdown-menu">
      <div className='sub-drop'>
      <ul className='drop1'>
      <h3>  Use Cases </h3>
      <li>
        <a href="/solution1">Employee Onboarding</a>
      </li>
      <li>
        <a href="/solution2">Compliance Training</a>
      </li>
      <li>
        <a href="/solution3">Sales Enablement</a>
      </li>
      <li>
        <a href="/solution4">Talent Development</a>
      </li> <li>
        <a href="/solution5">Customer Education</a>
      </li> <li>
        <a href="/solution6">Partner Enablement</a>
      </li> <li>
        <a href="/solution7">Member Training</a>
      </li>
      <li>
        <a href="/solution8">Front-line Work Force</a>
      </li>
      </ul>
    <ul className='drop2'>
    <h3>   Industries </h3>

    <li>
        <a href="/solution9">Software</a>
      </li>
      <li>
        <a href="/solution10">Financial Services</a>
      </li>
      <li>
        <a href="/solution11">Manufacturing</a>
      </li>
      <li>
        <a href="/solution12">Health Care</a>
      </li>   <li>
        <a href="/solution13">Government</a>
      </li>   <li>
        <a href="/solution14">Restaurant & Hospitality </a>
      </li>   <li>
        <a href="/solution15">Tech/IT Services</a>
      </li>
      <li>
        <a href="/solution3.html"> Education</a>
      </li>
    </ul>
    </div>
    </div>

  )}
</div>
      </li> */}

          {/* <li>         
       <div className="dropdown">
  <button onClick={toggleDropdown} className="dropdown-button">
     Solutions &#9662;
  </button>
  {isOpen && (
    
    <div className="dropdown-menu">
      <div className='sub-drop'>
      <ul className='drop1'>
      <h3>  Use Cases </h3>
      <li>
        <a href="/solution1">Employee Onboarding</a>
      </li>
      <li>
        <a href="/solution2">Compliance Training</a>
      </li>
      <li>
        <a href="/solution3">Sales Enablement</a>
      </li>
      <li>
        <a href="/solution4">Talent Development</a>
      </li> <li>
        <a href="/solution5">Customer Education</a>
      </li> <li>
        <a href="/solution6">Partner Enablement</a>
      </li> <li>
        <a href="/solution7">Member Training</a>
      </li>
      <li>
        <a href="/solution8">Front-line Work Force</a>
      </li>
      </ul>
    <ul className='drop2'>
    <h3>   Industries </h3>

    <li>
        <a href="/solution9">Software</a>
      </li>
      <li>
        <a href="/solution10">Financial Services</a>
      </li>
      <li>
        <a href="/solution11">Manufacturing</a>
      </li>
      <li>
        <a href="/solution12">Health Care</a>
      </li>   <li>
        <a href="/solution13">Government</a>
      </li>   <li>
        <a href="/solution14">Restaurant & Hospitality </a>
      </li>   <li>
        <a href="/solution15">Tech/IT Services</a>
      </li>
      <li>
        <a href="/solution3.html"> Education</a>
      </li>
    </ul>
    </div>
    </div>

  )}
</div>
      </li> */}
          {/* <li>
            <li><Link to="/about">About Us</Link></li>
          </li>
          <li>
            <li><Link to="/testimonial">Testimonial</Link></li>
          </li>
          <li>
            <li><Link to="/contact">Contact Us</Link></li>
          </li>
        </ul>
      </div> */}
    </nav>
  );
}

export default Header;
